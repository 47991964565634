// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { PageType } from '~/common/utilities/pageInfo';

export type TodoPageTypes =
  | PageType.Todo
  | PageType.TodoDone
  | PageType.TodoAll;

export const TodoPages: TodoPageTypes[] = [
  PageType.Todo,
  PageType.TodoDone,
  PageType.TodoAll,
];

export const getTodoPageTitle = (page: TodoPageTypes): string => {
  switch (page) {
    case PageType.Todo:
      return 'Incomplete only';
    case PageType.TodoDone:
      return 'Complete only';
    case PageType.TodoAll:
      return 'All todos';
  }
};
