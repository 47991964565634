// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import {
  forwardRef,
  InputProps,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { MdArrowDropDown, MdCheck, MdOutlineFilterAlt } from 'react-icons/md';
import { BlowfishButton } from '~/components/BlowfishButton';
import { ReactIcon } from '~/components/Icons';
import {
  getTodoPageTitle,
  TodoPages,
  TodoPageTypes,
} from '~/containers/common/Todo/TodoPages';

export interface Props {
  page: TodoPageTypes;
}

export const TodoTypeDropdown = forwardRef<
  MenuButtonProps & InputProps & Props,
  'button'
>(({ page, ...props }, ref) => {
  const pageTitle = getTodoPageTitle(page);
  return (
    <Menu>
      <BlowfishButton
        ref={ref}
        as={MenuButton}
        size="sm"
        variant="npGhostDust"
        leftIcon={
          <ReactIcon
            as={MdOutlineFilterAlt}
            display={['inline-flex', 'inline-flex', 'none']}
            size="sm"
          />
        }
        rightIcon={<ReactIcon as={MdArrowDropDown} size="sm" />}
        aria-label={pageTitle}
        {...props}
      >
        {pageTitle}
      </BlowfishButton>
      <MenuList>
        {TodoPages.map((it) => (
          <NextLink key={it} href={it} passHref>
            <MenuItem>
              <Text fontWeight={it === page ? 'medium' : 'light'}>
                {getTodoPageTitle(it)}
                {it === page && <ReactIcon as={MdCheck} mx="2"></ReactIcon>}
              </Text>
            </MenuItem>
          </NextLink>
        ))}
      </MenuList>
    </Menu>
  );
});
