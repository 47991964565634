// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import {
  Button,
  Flex,
  Heading,
  Link,
  Menu,
  MenuList,
  Spinner,
  Text,
} from '@chakra-ui/react';
import Image from 'next/image';
import { FC } from 'react';
import { isFirefox } from 'react-device-detect';
import { MdOpenInNew } from 'react-icons/md';
import { Order } from '~/common/gql';
import { chromeExtUrl, firefoxExtUrl } from '~/common/links';
import { PageType } from '~/common/utilities/pageInfo';
import { BookmarkInput } from '~/components/BookmarkInput/BookmarkInput';
import { useBookmarkInput } from '~/components/BookmarkInput/useBookmarkInput';
import { BreadcrumbBar } from '~/components/BreadcrumbBar/BreadcrumbBar';
import { BreadcrumbBarItem } from '~/components/BreadcrumbBar/BreadcrumbBarItem';
import { BreadcrumbBarSpacer } from '~/components/BreadcrumbBar/BreadcrumbBarSpacer';
import { BreadcrumbHeading } from '~/components/BreadcrumbBar/BreadcrumbHeading';
import { FancyBox, FancyButton } from '~/components/FancyComponents';
import { ReactIcon } from '~/components/Icons/ReactIcon';
import { InfiniteScrollView } from '~/components/InfiniteScrollView';
import { TabPage } from '~/components/TabPage';
import { InstallBrowserExtHintAd } from '~/containers/common/Ads/InstallBrowserExtHintAd/InstallBrowserExtHintAd';
import { InstallPWAHintAd } from '~/containers/common/Ads/InstallPWAHintAd/InstallPWAHintAd';
import { AddOptionsMenuButton } from '~/containers/common/App/AddOptionsMenu/AddOptionsMenuButton';
import { AddOptionsMenuList } from '~/containers/common/App/AddOptionsMenu/AddOptionsMenuList';
import { HeaderAppNavBar } from '~/containers/common/App/HeaderAppNavBar';
import { LeftSidebarDrawerOpenButton } from '~/containers/common/App/LeftSidebar/LeftSidebarDrawerOpenButton';
import { CollectionLayout } from '~/containers/common/CollectionLayout/CollectionLayout';
import { CollectionLayoutMenuButton } from '~/containers/common/CollectionLayout/CollectionLayoutMenuButton';
import { CollectionLayoutMenuGroup } from '~/containers/common/CollectionLayout/CollectionLayoutMenuGroup';
import { PrivateCollectionCardLayout } from '~/containers/common/CollectionLayout/PrivateCollectionCardLayout';
import { useCollectionLayout } from '~/containers/common/CollectionLayout/useCollectionLayout';
import { ProblemsFetchingBookmarksOopsMessage } from '~/containers/common/OopsMessage/ProblemsFetchingBookmarksOopsMessage';
import { TodoPageTypes } from '~/containers/common/Todo/TodoPages';
import { AppLayout } from '~/containers/layouts';
import { TodoTypeDropdown } from '~/containers/pages/TodoPage/TodoTypeDropdown';
import { useInputHintAd } from '~/hooks/useInputHintAd';
import { useItemsQueryPagingHelper } from '~/hooks/useItemsQueryPagingHelper';
import Illustration from '~/images/illustration-no-todos.svg';

export interface Props {
  todoPage: TodoPageTypes;
}

export const TodoPage: FC<Props> = ({ todoPage }) => {
  const fetchMarkedDone =
    todoPage === PageType.TodoAll
      ? undefined
      : todoPage === PageType.TodoDone
        ? true
        : false;

  const collectionLayout = useCollectionLayout();

  const {
    items,
    loading: loadingNextPage,
    error,
    prevPage,
    nextPage,
    pagingError,
    hasMore,
    called,
  } = useItemsQueryPagingHelper({
    collectionId: undefined,
    todosOnly: true,
    todoOptions: {
      markedDone: fetchMarkedDone,
      sortByTodoCreatedAt: false,
    },
  });

  const itemsMarkedDoneQuery = useItemsQueryPagingHelper({
    collectionId: undefined,
    todosOnly: true,
    todoOptions: {
      markedDone: true,
      sortByTodoCreatedAt: false,
    },
    skip: todoPage !== PageType.Todo,
    pagination: {
      limit: 3,
      order: Order.Desc,
    },
  });

  const bookmarkInput = useBookmarkInput({
    bookmarkType: {
      isTodo: true,
    },
    onSuccess: prevPage,
  });

  const adProps = useInputHintAd({ isAddingBookmark: bookmarkInput.focused });

  const nonZeroItems = items?.length || false;
  const bookmarksFailedToLoad = error || pagingError;

  const isListEmpty =
    !bookmarksFailedToLoad && called && !loadingNextPage && !nonZeroItems;

  // this hint is helpful only in the todo page when the user has more than 3 to dos
  // and no items have been marked done
  const isCardTodoMarkDoneHintHelpful =
    todoPage == PageType.Todo &&
    items?.length >= 3 &&
    itemsMarkedDoneQuery.called &&
    !itemsMarkedDoneQuery.loading &&
    !itemsMarkedDoneQuery.error &&
    itemsMarkedDoneQuery.items?.length === 0;

  return (
    <AppLayout>
      <TabPage>
        <HeaderAppNavBar desktopOnly />
        <BreadcrumbBar>
          <BreadcrumbBarItem collapse={bookmarkInput.active} mobileOnly>
            <LeftSidebarDrawerOpenButton>To do</LeftSidebarDrawerOpenButton>
          </BreadcrumbBarItem>
          <BreadcrumbBarItem collapse={bookmarkInput.active} desktopOnly>
            <BreadcrumbHeading>To do</BreadcrumbHeading>
          </BreadcrumbBarItem>
          <BreadcrumbBarSpacer collapse={bookmarkInput.active} />
          <BreadcrumbBarItem collapse={bookmarkInput.active}>
            <TodoTypeDropdown page={todoPage} />
          </BreadcrumbBarItem>
          <BreadcrumbBarItem collapse={bookmarkInput.active}>
            <Menu>
              <CollectionLayoutMenuButton layout={collectionLayout.layout} />
              <MenuList>
                <CollectionLayoutMenuGroup
                  layout={collectionLayout.layout}
                  setLayout={collectionLayout.setLayout}
                />
              </MenuList>
            </Menu>
          </BreadcrumbBarItem>
          <BreadcrumbBarItem
            collapse={!bookmarkInput.active}
            width={!bookmarkInput.active ? 0 : 'full'}
          >
            <BookmarkInput {...bookmarkInput.props} />
          </BreadcrumbBarItem>
          <BreadcrumbBarItem collapse={bookmarkInput.active}>
            <Menu>
              <AddOptionsMenuButton />
              <AddOptionsMenuList
                onAddBookmark={() => bookmarkInput.setActive(true)}
              />
            </Menu>
          </BreadcrumbBarItem>
        </BreadcrumbBar>
        <Flex direction="column" gap={3}>
          <FancyBox desktopOnly>
            <InstallBrowserExtHintAd {...adProps.extAdProps} />
          </FancyBox>
          <FancyBox mobileOnly>
            <InstallPWAHintAd {...adProps.pwaAdProps} />
          </FancyBox>
          {!nonZeroItems && loadingNextPage && <Spinner alignSelf="center" />}
          {nonZeroItems && (
            <InfiniteScrollView
              count={items.length}
              loading={loadingNextPage}
              hasMore={hasMore}
              onNextPage={nextPage}
            >
              <CollectionLayout layout={collectionLayout.layout}>
                {items.map((it, idx) => (
                  <PrivateCollectionCardLayout
                    key={it.id}
                    layout={collectionLayout.layout}
                    item={it}
                    showCardTodoMarkDoneHint={
                      isCardTodoMarkDoneHintHelpful && idx === 0
                    }
                    showCardManageTagsHint={idx === 0}
                  />
                ))}
              </CollectionLayout>
            </InfiniteScrollView>
          )}
          {isListEmpty && (
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              pt="10vh"
            >
              <Image src={Illustration} height={97} width={132} alt="" />
              <Heading size="lg" textAlign="center">
                {todoPage === PageType.Todo && (
                  <span>You have no pending to dos</span>
                )}
                {todoPage === PageType.TodoDone && (
                  <span>You have no completed to dos</span>
                )}
                {todoPage === PageType.TodoAll && (
                  <span>You have no to dos</span>
                )}
              </Heading>
              <Text fontSize="md" mt={2}>
                Mark an interesting item as “To do” and it will show up here.
              </Text>
              <Flex
                direction={['column', 'column', 'row']}
                mt={[8, 8, 4]}
                alignItems="center"
              >
                {isFirefox && (
                  <Link href={firefoxExtUrl} isExternal>
                    <FancyButton
                      variant="npGhostDust"
                      size="sm"
                      leftIcon={<ReactIcon as={MdOpenInNew} size="sm" />}
                      desktopOnly
                    >
                      Firefox extension
                    </FancyButton>
                  </Link>
                )}
                {!isFirefox && (
                  <Link href={chromeExtUrl} isExternal>
                    <FancyButton
                      variant="npGhostDust"
                      size="sm"
                      leftIcon={<ReactIcon as={MdOpenInNew} size="sm" />}
                      desktopOnly
                    >
                      Chrome extension
                    </FancyButton>
                  </Link>
                )}
                <Link href={PageType.GuideHowToUseTheBookmarklet} isExternal>
                  <Button
                    variant="npGhostDust"
                    size="sm"
                    leftIcon={<ReactIcon as={MdOpenInNew} size="sm" />}
                  >
                    Bookmarklet
                  </Button>
                </Link>
                <Link href={PageType.GuideHowToSaveAWebPage} isExternal>
                  <Button
                    variant="npGhostDust"
                    size="sm"
                    leftIcon={<ReactIcon as={MdOpenInNew} size="sm" />}
                  >
                    PWA (Android)
                  </Button>
                </Link>
              </Flex>
            </Flex>
          )}
          {bookmarksFailedToLoad && <ProblemsFetchingBookmarksOopsMessage />}
        </Flex>
      </TabPage>
    </AppLayout>
  );
};
